import { Link } from 'gatsby';
import * as React from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import './index.css';
import { StaticImage } from 'gatsby-plugin-image';

export default function Gallery() {
  return (
    <Layout>
      <Seo title="Gallery" />
      <div className="FaqContent">
        <h1 style={{ marginBottom: '2.5rem' }}>Gallery</h1>

        <p>
          Help us capture photos and videos of our day, it's going to be busy
          for us so there's a lot of amazing moments we're likely to miss. After
          the wedding, once you're home and recovered, scan the QR code below to
          upload any photos to our communal photo album. The more the merrier!
        </p>
        <div className="code-wrangler">
          <StaticImage
            width={200}
            height={200}
            placeholder="dominantColor"
            src="../../assets/images/qr-code.webp"
            alt={'QR code for gallery'}
          />
        </div>
        <p>
          You can also access the photo album{' '}
          <Link to="https://bit.ly/3NaysH6" target="_blank">
            here
          </Link>
          .
        </p>
      </div>
    </Layout>
  );
}
